<mat-radio-group
  [(ngModel)]="value"
  class="radio-button-list"
  [attr.aria-label]="'ARIA_LABEL.SELECT_PAYMENT_METHOD' | translate"
>
  <ng-container *ngIf="showAsAccordion; else showAsList">
    <ng-container *ngFor="let linkedFi of linkedFis | sort : 'withDefaultAccount'; let i = index">
      <mat-expansion-panel
        [expanded]="linkedFi.withDefaultAccount || i === selectedFiIndex"
        [attr.id]="'fi_accordion_toggle_' + linkedFi.display_name + '_' + i"
      >
        <div
          id="fi_no_available_account_message_{{ i }}"
          class="flex-row flex-start mt-16"
          *ngIf="!linkedFi.accounts?.length"
        >
          <mat-icon class="icon-smd mr-12 material-icons-outlined text-warn" id="help_icon"
            >gpp_maybe_outlined</mat-icon
          >
          <span class="mat-headline-4">{{ 'FI.ACCOUNT.NO_AVAILABLE.MESSAGE' | translate }}</span>
        </div>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <pbb-address-suggestion
              id="{{ linkedFi.display_name | idFormat }}_{{ linkedFi.fi_user_id }}_address_suggestion"
              [financialInstitution]="linkedFi"
              [hideSuggestion]="true"
            >
            </pbb-address-suggestion>
            <mat-chip-listbox *ngIf="linkedFi.isAddedLast" [attr.aria-label]="'ARIA_LABEL.FI_FLAGS' | translate">
              <mat-basic-chip
                class="new-account-chip ml-16"
                id="newly_added_fi_{{ linkedFi.display_name | idFormat }}_{{ linkedFi.fi_user_id }}"
                [attr.aria-label]="'ARIA_LABEL.NEWLY_ADDED_FI' | translate"
              >
                <pbb-svg-icon [icon]="'stars'" class="icon-smd mr-4"></pbb-svg-icon
                ><span class="mat-body-2">{{ 'COMMON.NEWLY_ADDED' | translate }}</span>
              </mat-basic-chip>
            </mat-chip-listbox>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-radio-button
          id="{{ linkedFi.display_name | idFormat }}_{{ linkedFi.fi_user_id }}_{{
            account.display_name_en | idFormat
          }}_{{ account.account_last_digits }}"
          *ngFor="let account of linkedFi.accounts | sort : 'isDefault'"
          labelPosition="after"
          [disabled]="!!account.disable_reason"
          [value]="
            {
              selectedAccountId: account.fi_account_ref,
              selectedFiId: linkedFi.linked_fi_id,
              selectedFiUserId: linkedFi.fi_user_id
            } | jsonStringify
          "
          [class]="'mat-body-2 ' + (!!account.disable_reason ? 'disabled' : '')"
        >
          <div class="user-account-radio-container">
            <div class="user-account-label">
              <div class="flex-row">
                <div class="user-account-logo">
                  <pbb-svg-icon
                    id="{{ linkedFi.display_name | idFormat }}_{{ linkedFi.fi_user_id }}_{{
                      account.display_name_en | idFormat
                    }}_{{ account.account_last_digits }}_logo"
                    alt=""
                    width="38px"
                    height="38px"
                    [icon]="account.accountLogo || ''"
                  ></pbb-svg-icon>
                </div>
                <div class="flex-col flex-start">
                  <span
                    class="user-account-display-name break-anywhere"
                    id="{{ linkedFi.display_name | idFormat }}_{{ linkedFi.fi_user_id }}_{{
                      account.display_name_en | idFormat
                    }}_{{ account.account_last_digits }}_text"
                    >{{ account.displayName }}</span
                  >
                  <span
                    class="user-account-type break-anywhere"
                    id="{{ linkedFi.display_name | idFormat }}_{{ linkedFi.fi_user_id }}_{{
                      account.display_name_en | idFormat
                    }}_{{ account.account_last_digits }}_type_digits"
                    >{{ account.accountTypeKey | translate }} (Ending in {{ account.account_last_digits }})</span
                  >
                  <small *ngIf="account.disable_reason" class="preline">{{
                    'FI.ACCOUNT.DISABLE_REASON.' + account.disable_reason | translate
                  }}</small>
                  <mat-chip-listbox
                    *ngIf="account.fi_account_ref === defaultAccountId"
                    [attr.aria-label]="'ARIA_LABEL.PAYMENT_METHOD_FLAGS' | translate"
                  >
                    <mat-basic-chip
                      [attr.aria-label]="'ARIA_LABEL.DEFAULT_PAYMENT_METHOD' | translate"
                      class="default-chip primary-chip"
                      disableRipple="true"
                      id="{{ linkedFi.display_name | idFormat }}_{{ linkedFi.fi_user_id }}_{{
                        account.display_name_en | idFormat
                      }}_{{ account.account_last_digits }}_default_chip"
                    >
                      <mat-icon aria-hidden="true">check_circle_outlined</mat-icon
                      ><span class="mat-caption">{{ 'COMMON.DEFAULT' | translate }}</span>
                    </mat-basic-chip>
                  </mat-chip-listbox>
                </div>
              </div>
            </div>
            <pbb-menu
              *ngIf="account.fi_account_ref !== defaultAccountId && showSetDefault"
              idLabel="{{ linkedFi.display_name | idFormat }}_{{ linkedFi.fi_user_id }}_{{
                account.display_name_en | idFormat
              }}_{{ account.account_last_digits }}"
              [options]="[
                {
                  action: Action.DEFAULT,
                  label: 'COMMON.PAYMENT_METHOD.SET_DEFAULT_PAYMENT',
                  icon: 'check',
                  showConfirm: false
                }
              ]"
              [item]="account"
              [itemRef]="account.fi_account_ref"
              [menuAriaLabel]="linkedFi?.display_name + ' ' + account.display_name"
              [itemIndex]="i"
              (menuAction)="setPaymentMethod($event, linkedFi.linked_fi_id, account.fi_account_ref)"
            ></pbb-menu>
          </div>
        </mat-radio-button>
      </mat-expansion-panel>
      <hr class="mt-24 mb-24" />
    </ng-container>
  </ng-container>
  <ng-template #showAsList>
    <ng-container *ngFor="let linkedFi of linkedFis | sort : 'withDefaultAccount'; let i = index">
      <div class="flex-row">
        <pbb-address-suggestion
          id="{{ linkedFi.display_name | idFormat }}_{{ linkedFi.fi_user_id }}_address_suggestion"
          [financialInstitution]="linkedFi"
          [hideSuggestion]="true"
          *ngIf="showFi"
        >
        </pbb-address-suggestion>
        <mat-chip-listbox *ngIf="linkedFi.isAddedLast" [attr.aria-label]="'ARIA_LABEL.FI_FLAGS' | translate">
          <mat-basic-chip
            class="new-account-chip ml-16"
            id="newly_added_fi_{{ linkedFi.display_name | idFormat }}_{{ linkedFi.fi_user_id }}"
            [attr.aria-label]="'ARIA_LABEL.NEWLY_ADDED_FI' | translate"
          >
            <pbb-svg-icon [icon]="'stars'" class="icon-smd mr-4"></pbb-svg-icon
            ><span class="mat-body-2">{{ 'COMMON.NEWLY_ADDED' | translate }}</span>
          </mat-basic-chip>
        </mat-chip-listbox>
      </div>

      <div
        id="fi_no_available_account_message_{{ i }}"
        class="flex-row flex-start mt-16"
        *ngIf="!linkedFi.accounts?.length"
      >
        <mat-icon class="icon-smd mr-12 material-icons-outlined text-warn" id="help_icon">gpp_maybe_outlined</mat-icon>
        <span class="mat-headline-4">{{ 'FI.ACCOUNT.NO_AVAILABLE.MESSAGE' | translate }}</span>
      </div>
      <ng-container *ngFor="let account of linkedFi.accounts | sort : 'isDefault'">
        <mat-radio-button
          id="{{ linkedFi.display_name | idFormat }}_{{ linkedFi.fi_user_id }}_{{
            account.display_name_en | idFormat
          }}_{{ account.account_last_digits }}"
          labelPosition="after"
          [disabled]="!!account.disable_reason"
          [value]="
            {
              selectedAccountId: account.fi_account_ref,
              selectedFiId: linkedFi.linked_fi_id,
              selectedFiUserId: linkedFi.fi_user_id
            } | jsonStringify
          "
          [class]="'mat-body-2 ' + (!!account.disable_reason ? 'disabled' : '')"
        >
          <div class="user-account-radio-container">
            <div class="user-account-label">
              <div class="flex-row">
                <div class="user-account-logo">
                  <pbb-svg-icon
                    id="{{ linkedFi.display_name | idFormat }}_{{ linkedFi.fi_user_id }}_{{
                      account.display_name_en | idFormat
                    }}_{{ account.account_last_digits }}_logo"
                    alt=""
                    width="38px"
                    height="38px"
                    [icon]="account.accountLogo || ''"
                  ></pbb-svg-icon>
                </div>
                <div class="flex-col flex-start">
                  <span
                    class="user-account-display-name break-anywhere"
                    id="{{ linkedFi.display_name | idFormat }}_{{ linkedFi.fi_user_id }}_{{
                      account.display_name_en | idFormat
                    }}_{{ account.account_last_digits }}_text"
                    >{{ account.displayName }}</span
                  >
                  <span
                    class="user-account-type break-anywhere"
                    id="{{ linkedFi.display_name | idFormat }}_{{ linkedFi.fi_user_id }}_{{
                      account.display_name_en | idFormat
                    }}_{{ account.account_last_digits }}_type_digits"
                    >{{ account.accountTypeKey | translate }} (Ending in {{ account.account_last_digits }})</span
                  >
                  <small *ngIf="account.disable_reason" class="preline">{{
                    'FI.ACCOUNT.DISABLE_REASON.' + account.disable_reason | translate
                  }}</small>
                  <mat-chip-listbox
                    *ngIf="account.fi_account_ref === defaultAccountId"
                    [attr.aria-label]="'ARIA_LABEL.PAYMENT_METHOD_FLAGS' | translate"
                  >
                    <mat-basic-chip
                      [attr.aria-label]="'ARIA_LABEL.DEFAULT_PAYMENT_METHOD' | translate"
                      class="default-chip primary-chip"
                      disableRipple="true"
                      id="{{ linkedFi.display_name | idFormat }}_{{ linkedFi.fi_user_id }}_{{
                        account.display_name_en | idFormat
                      }}_{{ account.account_last_digits }}_default_chip"
                    >
                      <mat-icon aria-hidden="true">check_circle_outlined</mat-icon
                      ><span class="mat-caption">{{ 'COMMON.DEFAULT' | translate }}</span>
                    </mat-basic-chip>
                  </mat-chip-listbox>
                </div>
              </div>
            </div>
            <pbb-menu
              *ngIf="account.fi_account_ref !== defaultAccountId && showSetDefault"
              idLabel="{{ linkedFi.display_name | idFormat }}_{{ linkedFi.fi_user_id }}_{{
                account.display_name_en | idFormat
              }}_{{ account.account_last_digits }}"
              [options]="[
                {
                  action: Action.DEFAULT,
                  label: 'COMMON.PAYMENT_METHOD.SET_DEFAULT_PAYMENT',
                  icon: 'check',
                  showConfirm: false
                }
              ]"
              [item]="account"
              [itemRef]="account.fi_account_ref"
              [menuAriaLabel]="linkedFi?.display_name + ' ' + account.display_name"
              [itemIndex]="i"
              (menuAction)="setPaymentMethod($event, linkedFi.linked_fi_id, account.fi_account_ref)"
            ></pbb-menu>
          </div>
        </mat-radio-button>
      </ng-container>
      <hr *ngIf="linkedFis.length > 1" class="mt-24 mb-24" />
    </ng-container>
  </ng-template>
</mat-radio-group>
